<template>
  <div class="updata">
    <Nav></Nav>
    <div class="updata-con desktop-present">
      <div class="updata-title">
        您的位置：<span style="color: red">首页</span>>>个人信息>>修改个人信息
      </div>
      <div class="updata-detail">
        <!-- <div>
          <span>中文名：</span>
          <el-input v-model="myInfo.chnName"></el-input>
        </div>
        <div>
          <span>登录名:</span> <el-input v-model="myInfo.loginName"></el-input>
        </div> -->
        <div>
          <span>默认笔名:</span>
          <el-input v-model="myInfo.defaultAuthorName"></el-input>
        </div>
        <div>
          <span>工作单位：</span>
          <el-input v-model="myInfo.company"></el-input>
        </div>
        <div>
          <span>头衔:</span>
          <el-input v-model="myInfo.head"></el-input>
        </div>
        <div>
          <span>地址:</span>
          <el-input v-model="myInfo.address"></el-input>
        </div>
        <div>
          <span>邮编: </span
          ><el-input v-model="myInfo.postCode" @blur="onpostCode"></el-input>
        </div>
        <div>
          <span>备注: </span><el-input v-model="myInfo.comment"></el-input>
        </div>
        <div>
          <span>邮箱: </span
          ><el-input v-model="myInfo.email" @blur="onSendEmail"></el-input>
        </div>
      </div>
      <div class="btn">
        <el-button @click="onEnsure">确认修改</el-button>
      </div>
    </div>
    <div class="updata-con mobile-present">
      <div class="updata-title">
        您的位置：<span style="color: red">首页</span>>>个人信息>>修改个人信息
      </div>
      <div class="updata-detail">
        <!-- <div>
          <span>中文名：</span>
          <el-input v-model="myInfo.chnName"></el-input>
        </div>
        <div>
          <span>登录名:</span> <el-input v-model="myInfo.loginName"></el-input>
        </div> -->
        <div>
          <span>默认笔名:</span>
          <el-input v-model="myInfo.defaultAuthorName"></el-input>
        </div>
        <div>
          <span>工作单位：</span>
          <el-input v-model="myInfo.company"></el-input>
        </div>
        <div>
          <span>职位:</span>
          <el-input v-model="myInfo.head"></el-input>
        </div>

        <div>
          <span>地址:</span>
          <el-input v-model="myInfo.address"></el-input>
        </div>
        <div>
          <span>邮编: </span
          ><el-input v-model="myInfo.postCode" @blur="onpostCode"></el-input>
        </div>
        <div>
          <span>备注: </span><el-input v-model="myInfo.comment"></el-input>
        </div>
        <div>
          <span>邮箱: </span
          ><el-input v-model="myInfo.email" @blur="onSendEmail"></el-input>
        </div>
      </div>
      <div class="btn">
        <el-button @click="onEnsure">确认修改</el-button>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      myInfo: { extra: {} }, // 个人信息
      info: {}, //原始信息
    };
  },
  created() {
    var thiz = this;
    this.reload();
    RD.pure()
      .user()
      .sync()
      .then((data) => {
        thiz.myInfo = data;
        console.log("info");
      })
      .catch((error) => {
        console.log("err", error);
        if (error.code == "-1") {
          thiz.$message("需要登录");
          thiz.$router.push("/index.html");
        }
      });
  },
  methods: {
    reload() {
      var thiz = this;
      thiz.info = JSON.parse(JSON.stringify(thiz.myInfo));
    },
    // 邮箱验证
    onSendEmail() {
      var thiz = this;
      var regEmail =
        /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
      if (thiz.myInfo.email != "" && !regEmail.test(thiz.myInfo.email)) {
        this.$message({
          message: "邮箱格式不正确",
          type: "error",
        });
      }
    },
    // 邮编的验证
    onpostCode() {
      var thiz = this;
      var code =
        /^(0[1-7]|1[0-356]|2[0-7]|3[0-6]|4[0-7]|5[1-7]|6[1-7]|7[0-5]|8[013-6])\d{4}$/;
      if (thiz.myInfo.postCode != "" && !code.test(thiz.myInfo.postCode)) {
        thiz.$message("邮编不合法, 请重新填写");
      }
    },
    // 身份证号验证
    onSendIdcard() {
      var thiz = this;
      var regIdcard =
        /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
      if (
        thiz.myInfo.extra.idNo != "" &&
        !regIdcard.test(thiz.myInfo.extra.idNo)
      ) {
        this.$message({
          message: "身份证格式不正确",
          type: "error",
        });
      }
    },
    // 验证微信
    onweixin() {
      var thiz = this;
      var regweiixn = /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/;
      if (thiz.myInfo.extra.wx != "" && !regweiixn.test(thiz.myInfo.extra.wx)) {
        this.$message({
          message: "微信格式不正确",
          type: "error",
        });
      }
    },
    onEnsure() {
      var thiz = this;
      console.log("shhs", thiz.myInfo);

      var code =
        /^(0[1-7]|1[0-356]|2[0-7]|3[0-6]|4[0-7]|5[1-7]|6[1-7]|7[0-5]|8[013-6])\d{4}$/;
      if (
        thiz.myInfo.postCode &&
        thiz.myInfo.postCode != "" &&
        !code.test(thiz.myInfo.postCode)
      ) {
        thiz.$message("邮编不合法, 请重新填写");
        return;
      }

      var regEmail =
        /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
      if (
        thiz.myInfo.email &&
        thiz.myInfo.email != "" &&
        !regEmail.test(thiz.myInfo.email)
      ) {
        this.$message({
          message: "邮箱格式不正确",
          type: "error",
        });
        return;
      }

      if (
        thiz.myInfo.defaultAuthorName == "" ||
        thiz.myInfo.defaultAuthorName == undefined
      ) {
        thiz.$message("默认笔名不能为空");
        return;
      }
      if (thiz.myInfo.company == "" || thiz.myInfo.company == undefined) {
        thiz.$message("工作单位不能为空");
        return;
      }

      if (thiz.myInfo.address == "" || thiz.myInfo.address == undefined) {
        thiz.$message("地址不能为空");
        return;
      }

      if (thiz.myInfo.comment == undefined || thiz.myInfo.comment == "") {
        thiz.$message("备注不能为空");
        return;
      }
      if (thiz.myInfo.postCode == "" || thiz.myInfo.postCode == undefined) {
        thiz.$message("邮编不能为空");
        return;
      }
      if (thiz.myInfo.email == "" || thiz.myInfo.email == undefined) {
        thiz.$message("邮箱不能为空");
        return;
      }
      if (thiz.myInfo.head == "" || thiz.myInfo.head == undefined) {
        thiz.$message("头衔不能为空");
        return;
      }

      var defaultName = thiz.myInfo.defaultAuthorName;
      var company = thiz.myInfo.company;
      var head = thiz.myInfo.head;
      var address = thiz.myInfo.address;
      var email = thiz.myInfo.email;
      var telephone = thiz.myInfo.telephone;
      var postCode = thiz.myInfo.postCode;
      var comment = thiz.myInfo.comment;
      var fax = thiz.myInfo.fax;
      var qq = thiz.myInfo.extra.qq;
      var wx = thiz.myInfo.extra.wx;
      var bank = thiz.myInfo.extra.bank;
      var bankAcc = thiz.myInfo.extra.bankAcc;
      var sheying = thiz.myInfo.extra.sheying;
      var xinwen = thiz.myInfo.extra.xinwen;
      var sheji = thiz.myInfo.extra.sheji;
      var topReward = thiz.myInfo.extra.topReward;
      var idNo = thiz.myInfo.extra.idNo;
      RD.user()
        .update(
          defaultName,
          company,
          head,
          address,
          postCode,
          comment,
          email,
          qq,
          wx,
          bank,
          idNo,
          sheji,
          xinwen,
          bankAcc,
          fax,
          sheying,
          telephone,
          topReward
        )
        .then(() => {
          thiz.$message("修改信息完成");
          thiz.$router.push("/myInfo.html");
        });
    },
    ondel() {
      var thiz = this;
      thiz.myInfo = thiz.info;
    },
  },
};
</script>

<style scoped lang="less">
.updata {
  height: auto;
  background-color: #f1f1f1;

  .updata-con.desktop-present {
    width: 120rem;
    margin: 0 auto;
    min-height: calc(100vh - 54rem);
    background-color: #dfdfdf;
    .updata-title {
      height: 4rem;
      background-color: #ccc;
      font-size: 1.4rem;
      line-height: 4.5rem;
      text-align: left;
      padding-left: 2rem;
    }
    .updata-detail {
      display: flex;
      flex-wrap: wrap;
      div {
        height: 4rem;
        text-align: left;
        font-size: 1.4rem;
        margin-left: 2rem;
        margin-bottom: 1rem;
        width: 48%;
        span {
          display: inline-block;
          width: 11.5rem;
        }
        .el-input {
          width: 50%;
        }
      }
    }
    .btn {
      height: 6rem;
      text-align: center;
      margin-top: 2rem;
    }
  }
  .updata-con.mobile-present {
    width: 750px;
    margin: 0 auto;
    min-height: 628px;
    background-color: #dfdfdf;
    .updata-title {
      height: 30px;
      background-color: #ccc;
      font-size: 16px;
      line-height: 4.5rem;
      text-align: left;
      padding-left: 20px;
    }
    .updata-detail {
      display: flex;
      flex-wrap: wrap;
      div {
        height: 40px;
        text-align: left;
        font-size: 16px;
        margin-left: 20px;
        margin-bottom: 1rem;
        width: 68%;
        span {
          display: inline-block;
          width: 125px;
        }
        .el-input {
          width: 50%;
        }
      }
    }
    .btn {
      height: 6rem;
      text-align: center;
      margin-top: 2rem;
    }
  }
}
</style>
